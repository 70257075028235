<template>
  <v-card :elevation="0">
    <v-toolbar tile flat>
      <v-toolbar-title>
        <h1 class="title font-weight-regular">
          {{ $tc('blog.ENTRY', 2) }}
        </h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon @click="closePanelTool">mdi-close</v-icon>
    </v-toolbar>

    <v-tabs
      v-model="tabs"
      slider-size="2"
      fixed-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="primary--text text-capitalize">
        {{ $t('GENERAL') }}
      </v-tab>
      <v-tab
        class="primary--text text-capitalize">
        {{ $t('AVANCED') }}
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col>
                <h1 class="subtitle-1">{{ $t('blog.COVERIMAGE') }}<v-icon>mdi-information-outline</v-icon></h1> 
              </v-col>
              <v-col cols="2 px-0">
                <div>
                  <v-switch
                    dense
                    :ripple="false"
                    class="float-right mt-0"
                    v-model="switch1"
                    inset>
                  </v-switch>
                </div>
              </v-col>
            </v-row>

            <v-hover v-if="switch1">
              <template v-slot:default="{ hover }">
                <v-card :elevation="editedItem.image !== null ? 1 : 0" class="mb-4 rounded-lg overflow-hidden">
                  <v-img 
                    v-if="editedItem.image !== null"
                    max-height="160"
                    class="rounded-lg cursor-pointer"
                    :src="require(`@/assets/${editedItem.image}`)" 
                    :alt="editedItem.slug">
                  </v-img>
                  <v-card v-else elevation="0" min-height="160" :color="$vuetify.theme.dark ? 'drawernumber' : 'grey lighten-4'">
                    <v-card-text>
                      <v-card :class="$vuetify.theme.dark ? 'custom-card2' : 'custom-card'" height="128" outlined elevation="0">
                        <v-card-text class="text-center pt-8">
                          <v-icon size="60" :color="$vuetify.theme.dark ? 'drawerbackground' : 'drawernumber'">
                            mdi-image
                          </v-icon>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      color="primary">
                      <v-btn color="accent" fab small>
                        <v-icon v-if="editedItem.image !== null" color="primary">
                          mdi-cached
                        </v-icon>
                        <v-icon v-else color="primary">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </template>
            </v-hover>
            
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  :label="$t('blog.PUBLISHDATE')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                :locale="$i18n.locale"
                ref="picker"
                v-model="editedItem.published"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                @input="menu = false"
                @change="saveDate"></v-date-picker>
            </v-menu>
            <v-text-field
              clearable
              prepend-inner-icon="mdi-account"
              v-model="editedItem.autor.name.first"
              :label="$t('blog.AUTOR')">
            </v-text-field>
            <v-row>
              <v-col>
                <h1 class="subtitle-1">
                {{ $t('blog.FEATUREDENTRY') }}<v-icon>mdi-information-outline</v-icon></h1> 
              </v-col>
              <v-col cols="2 px-0">
                <div>
                  <v-switch
                    dense
                    :ripple="false"
                    class="float-right mt-0"
                    v-model="editedItem.marked"
                    inset>
                  </v-switch>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-textarea
              clearable
              counter="140"
              outlined
              rows="4"
              v-model="editedItem.synopsis" >
              <template v-slot:label>
                <div>
                  {{ $t('SYNOPSIS') }}
                </div>
              </template>
            </v-textarea>

            <v-row>
              <v-col>
                <h1 class="subtitle-1">
                {{ $t('blog.RELATEDPOST') }} <v-icon>mdi-information-outline</v-icon></h1> 
              </v-col>
              <v-col cols="2">
                <h1 class="subtitle-1">
                {{ editedItem.relativeEntries.length }}/3</h1> 
              </v-col>
            </v-row>
            
            <v-card v-if="isEmptyRelatives(editedItem)"  class="mb-2" rounded="lg" outlined :elevation="0">
              <v-card-text class="pa-2">
                <v-chip-group
                  column
                  multiple>
                  <v-chip
                    v-for="item in editedItem.relativeEntries"
                    :key="item"
                    outlined>
                    {{ getEntryName(item) }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>

            <v-btn v-if="!isEmptyRelatives(editedItem)" text outlined color="primary">
              <v-icon left small>mdi-plus</v-icon> 
              {{ $t('blog.ADDENTRIES') }}
            </v-btn>

            <v-btn v-else text outlined color="primary">
              <v-icon left small>mdi-pencil</v-icon> 
              {{ $t('blog.EDITENTRIES') }}
            </v-btn>

            <v-row class="mt-2">
              <v-col>
                <h1 class="subtitle-1">
                {{ $t('blog.ALLOWCOMMENTS') }}<v-icon>mdi-information-outline</v-icon></h1> 
              </v-col>
              
              <v-col cols="2 px-0">
                <div>
                  <v-switch
                    dense
                    :ripple="false"
                    class="float-right mt-0"
                    v-model="editedItem.comments"
                    inset>
                  </v-switch>
                </div>
              </v-col>
            </v-row>
          
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { usePageStore } from '../stores/pageStore'
import { useBlogStore } from '../stores/blogStore'
import { usePromotionStore } from '../stores/promotionStore'

export default {
  name: "post-general-options",
  props: {
    editedItem: Object,
    moduleType: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      tabs: null,
      switch1: true,
      switch2: true,
      menu: false,
      blogStore: useBlogStore(),
      promotionStore: usePromotionStore(),
      pageStore: usePageStore()
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  computed: {
    computedDateFormatted () {
      return this.fortmatDate(this.editedItem.published)
    },
  },
  methods: {
    closePanelTool () {
      this.$emit('close')
    },
    saveDate (date) {
      this.$refs.menu.save(date)
    },
    isEmptyRelatives(value) {
      return value.relativeEntries.length > 0
    },
    getEntryName (id) {
      if (this.moduleType == "blog") {
        return this.blogStore.getBlogById(id).name;
      } else if (this.moduleType == "page") {
        return this.pageStore.getPageById(id).name;
      } else if (this.moduleType == "tags") {
        return this.promotionStore.getPromotionById(id).name;
      }
    },
    fortmatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      if (this.$i18n.locale === 'en')
        return `${month}/${day}/${year}`
      else
        return `${day}/${month}/${year}`
    },
  },
}
</script>

<style scoped>
  .v-card {
    width: 100%;
  }
  .custom-card {
    background:none;
    border: #cccccc dotted 2px;
  }
  .custom-card2 {
    background:#444444;
    border: #222222 dotted 2px;
  }
</style>
